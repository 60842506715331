/* import __COLOCATED_TEMPLATE__ from './top-line-card.hbs'; */
/* RESPONSIBLE TEAM: team-ai-insights */
import Component from '@glimmer/component';
import type { ReportState } from 'embercom/components/reporting/custom/report/chart-card';
import type { Job } from 'embercom/components/reporting/automation/ai-insights/fin-insights/jobs';
import { staticDataResource } from 'embercom/lib/reporting/static-data-resource';
import { use } from 'ember-resources/util/function-resource';
import type { ChartSeriesDefinition } from 'embercom/components/reporting/automation/ai-insights/fin-insights/jobs';
import { inject as service } from '@ember/service';
import type SuggestionService from 'embercom/services/suggestion-service';
import type ReportingMetrics from 'embercom/services/reporting-metrics';
import { formatTableValue } from 'embercom/lib/reporting/custom/view-config-builder-helpers';
import { shouldConvertNullsToZeros } from 'embercom/lib/reporting/flexible/data-response-helpers';
import type { FieldMetric } from 'embercom/objects/reporting/unified/metrics/types';
import type { MetricValue } from 'embercom/lib/reporting/chart-data-resource';

interface Signature {
  reportState: ReportState;
  selectedJob: Job;
}

export default class TopLineCard extends Component<Signature> {
  @service declare suggestionService: SuggestionService;
  @service declare reportingMetrics: ReportingMetrics;

  @use metricResource = staticDataResource(this, {
    serializedChart: () => this.toplineChartDefinition,
    reportState: () => this.args.reportState,
  });

  @use suggestionsTopicsResource = staticDataResource(this, {
    serializedChart: () => this.suggestionsTopicsChartDefinition,
    reportState: () => this.args.reportState,
  });

  get isLoading() {
    return (
      this.metricResource.isLoading ||
      (this.args.selectedJob.showSuggestions && this.suggestionsTopicsResource?.isLoading)
    );
  }

  get toplineChartDefinition() {
    return {
      chart_series: this.args.selectedJob.topLine.chartSeries.map(
        (series: ChartSeriesDefinition) => ({
          ...series,
          filters: this.args.selectedJob.topLine.filters,
        }),
      ),
      view_by: null,
    };
  }

  get suggestionsTopicsChartDefinition() {
    return {
      chart_series: this.args.selectedJob.topLine.chartSeries.map(
        (series: ChartSeriesDefinition) => ({
          ...series,
          filters: this.args.selectedJob.topLine.filters,
        }),
      ),
      view_by: 'conversation_custom_fields#ai_insights_topic',
      view_by_display_limit: 5000,
    };
  }

  get topLineMetricValue() {
    let metricValue = this.metricResource.kpiData[0];
    return this.formatMetricValue(this.toplineChartDefinition.chart_series[0], metricValue);
  }

  formatMetricValue(chartSeries: ChartSeriesDefinition, rawValue: MetricValue) {
    let metric = this.reportingMetrics.getMetricById(chartSeries.metric_id);
    let numericValue = this.numericValue(rawValue);
    let value = formatTableValue(numericValue, metric.unit);

    let aggregation = chartSeries.aggregation || (metric as FieldMetric).defaultAggregation;
    let defaultValue = shouldConvertNullsToZeros(aggregation, metric as any) ? 0 : '-';
    return value || defaultValue;
  }

  numericValue(value: MetricValue | null): number | null {
    if (typeof value === 'number') {
      return value;
    }
    return value?.value ?? null;
  }

  get suggestionCount() {
    let topics = Object.keys(this.suggestionsTopicsResource.flatCombinedData);
    return this.suggestionService.getSuggestionCountForTopics(topics);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Automation::AiInsights::FinInsights::TopLineCard': typeof TopLineCard;
    'reporting/automation/ai-insights/fin-insights/top-line-card': typeof TopLineCard;
  }
}
